const tokenInvData = [{
    "id": "2",
    "tokenAddress": "0xAed8bD0608ef3Cc45290a8d0E4223Ef4C92Dd3dc",
    "invDashAddress": "0xd69Df110228d84a8Cde410a259b5871536f879e0",
    "name": "Avoteo",
    "url_name": "avoteo",
    "symbol": "AVO",
    "chainId": "56",
    "decimals": "18",
    "totalSupply": "599999900",
    "createdAt": "2022-09-13T00:00:00.000Z",
    "updatedAt": "2022-09-13T00:00:00.000Z",
    "totalTokenLocked": "62307246",
    "usdPrice": 0.05,
    "imgUrl": "https://pbs.twimg.com/profile_images/1435974019161939968/fYKboxzo_400x400.jpg"
}]


export function getInvDashDetailsbyAddress(tokenAddress) {
    let pool = tokenInvData.filter((item) => item.tokenAddress.toLowerCase() === tokenAddress.toLowerCase())
    if (pool.length === 0) return undefined
    return pool[0]
}

export function getInvDashDetailsbyName(name) {
    if(name === undefined) {
        name="Avoteo"
    }
    let pool = tokenInvData.filter((item) => item.url_name.toLowerCase() === name.toLowerCase())
    if (pool.length === 0) return undefined
    return pool[0]
}
