import { InjectedConnector } from '@web3-react/injected-connector';
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletConnectV2Connector } from './walletconnectV2Connector';
// import { WalletConnectConnector } from "./wallet-connect-connector"

const isProd = true;

let devSupportedChainIds = [97]
let prodSupportedChainIds = [56]

let devRPC = {
    // 3: "https://ropsten.infura.io/v3/",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // 80001: "https://rpc-mumbai.matic.today",
    // 322: "https://rpc-testnet.kcc.network",
    // 43113: "https://api.avax-test.network/ext/bc/C/rpc",
    // 65: "https://exchaintestrpc.okex.org"
}

let prodRPC = {
    // 1: "https://mainnet.infura.io/v3/",
    56: "https://bsc-dataseed.binance.org/",
    // 137: "https://polygon-rpc.com/",
    // 321: "https://rpc-mainnet.kcc.network",
    // 43114: "https://api.avax.network/ext/bc/C/rpc",
    // 66: "https://exchainrpc.okex.org"
}

const prodChainDetails = [
    {
        title: "BSC",
        chainId: 56,
        icon: "../images/selectChainIcon/bsc.svg"
    },
    // {
    //     title: "Polygon",
    //     chainId: 137,
    //     icon: "../images/selectChainIcon/polygon.svg"
    // },
    // {
    //     title: "AVAX",
    //     chainId: 43114,
    //     icon: "../images/selectChainIcon/avax.svg"
    // },
    // {
    //     title: "Ethereum",
    //     chainId: 1,
    //     icon: "../images/selectChainIcon/eth.svg"
    // },
    // {
    //     title: "KCC",
    //     chainId: 321,
    //     icon: "../images/selectChainIcon/kcc.svg"
    // },
    // {
    //     title: "OEC",
    //     chainId: 66,
    //     icon: "../images/selectChainIcon/oec.svg"
    // }
]

const devChainDetails = [
    // {
    //     title: "Ropston",
    //     chainId: 3,
    //     icon: "../images/selectChainIcon/eth.svg"
    // },
    {
        title: "BSC",
        chainId: 97,
        icon: "../images/selectChainIcon/bsc.svg"
    },
    // {
    //     title: "Polygon",
    //     chainId: 80001,
    //     icon: "../images/selectChainIcon/polygon.svg"
    // },
    // {
    //     title: "AVAX",
    //     chainId: 43113,
    //     icon: "../images/selectChainIcon/avax.svg"
    // },
    // {
    //     title: "KCC",
    //     chainId: 322,
    //     icon: "../images/selectChainIcon/kcc.svg"
    // },
    // {
    //     title: "OEC",
    //     chainId: 65,
    //     icon: "../images/selectChainIcon/oec.svg"
    // }
]

export const injected = new InjectedConnector({
    supportedChainIds: isProd ? prodSupportedChainIds : devSupportedChainIds,
})

export const walletconnect: any = function (chainId: number, isTrustWallet = false) {
    return new WalletConnectV2Connector({
        projectId: "d212f10eefa9b7ceff71cac6b7f5b2d9",
        showQrModal: true,
        chains: [chainId],
        rpcMap: isProd ? prodRPC : devRPC
    })
}

export const walletconnectV1: any = function (chainId: number, isTrustWallet = false) {
    return new WalletConnectConnector({
        // supportedChainIds: devSupportedChainIds,
        // preferredNetworkId: 56,
        // rpc: devRPC,
        // supportedChainIds: prodSupportedChainIds,
        rpc: isProd ? prodRPC : devRPC,
        chainId: chainId,
        qrcode: isTrustWallet ? undefined : true,
        qrcodeModalOptions: isTrustWallet ? {
            mobileLinks: [
                "trust"
            ]
        } : undefined
    })
}

// export const walletconnect = 

export const chainSelectionDetails = isProd ? prodChainDetails : devChainDetails

export const gnosisSafe = new SafeAppConnector(); 
