import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Form, Row, Button, FormLabel, InputGroup, FormControl, Modal } from "react-bootstrap";
import { useParams } from "react-router";
import PageHeader from "../../components/PageHeader";
import RangeSlider from "../../components/RangeSlider";
import { getInvDashDetailsbyAddress, getInvDashDetailsbyName } from "../../constants/detail"
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "./lock-details-investor-vesting.scss";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { getExplorerUrl, getExplorerUrlFromName, getEllipsisAddress, isAddress, getExplorerNameFromName, getChainDataById } from "../../utils";
import { toast } from "react-toastify";
import { useClaimInvDash, useFetchInvDashDetails, useTransferOwnershipInvDash } from "../../hooks/useInvDash";
import IndividualInvDashTable from "../../components/IndividualInvDashTable";
import DotLoader from "react-spinners/DotLoader";

const InvestorVestingLockDetails = () => {
    const { account, chainId } = useWeb3React();
    dayjs.extend(relativeTime);
    const [data, setData] = useState<any>();
    const [networkData, setNetworkData] = useState<any>();
    const [message, setMessage] = useState<any>();
    let { tokenAddress } = useParams<{ tokenAddress: string }>();
    const [walletRequired, setWalletRequired] = useState(true);
    const [claimRequested, setClaimRequested] = useState(false);
    const [claimTxHash, setClaimTxHash] = useState('');
    const [lockList, setLockList] = useState<any>();
    const [isWithdrawOpen, setIsWithdrawOpen] = useState(false);
    const [isTotalVestedOpen, setIsTotalVestedOpen] = useState(false);
    const [tranferModal, setTranferModal] = useState(false);
    const [newBeneficiary, setNewBeneficiary] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [transferOwnershipRequested, setTransferOwnershipRequested] = useState(false);
    const [transferOwnershipTxHash, setTransferOwnershipTxHash] = useState("");
    const { onFetchInvDashDetails } = useFetchInvDashDetails();
    const { onClaimInvDash } = useClaimInvDash();
    const { onTransferOwnershipInvDash } = useTransferOwnershipInvDash();
    const override = `
        display: block;
        margin: 5rem auto;
        border-color: red;
    `;

    useEffect(() => {
        // const address = isAddress(tokenAddress);
        // if (!address) {
        //     toast.error("Invalid token address");
        //     return;
        // }
        let tokenLock: any = getInvDashDetailsbyName(tokenAddress)
        tokenLock.totalValueLocked = '$' + Number(tokenLock.totalTokenLocked * tokenLock.usdPrice ?? 0).toLocaleString()
        if (!tokenLock) {
            toast.warning("No lock found! Please hit reload in sometime, if you recently submitted Lock Request");
            return;
        } setData(tokenLock);
    }, []);

    useEffect(() => {
        let fetchDetails = async function () {
            if (!account) {
                setMessage("Please connect your wallet to check your vested tokens")
                return
            }
            if (Number(data.chainId) !== chainId) {
                setMessage(`Please switch your network to ${getChainDataById(Number(data.chainId))?.chainName}`)
                return
            }
            setMessage("")
            setIsLoading(true)
            let details = await onFetchInvDashDetails(data.invDashAddress, data.tokenAddress, Number(data.decimals), data.totalSupply)
            setIsLoading(false)
            console.log(details)
            setNetworkData(details)
        }
        if (data) fetchDetails()
    }, [data, chainId, account])

    useEffect(() => {
        if (!networkData) return
        // let release = []
        // for (let i = 0; i < networkData.locks.length; i++) {
        //     release.push(...networkData.locks[i].releaseStructure)
        // }
        // setLockList(release)
        // console.log
        setLockList(networkData.lockList)
    }, [networkData])

    const handleClaim = useCallback(async (depositId) => {
        try {
            if (claimRequested) return
            setClaimRequested(true)
            let tx = await onClaimInvDash(data.invDashAddress)
            if (tx) {
                setClaimTxHash(tx.hash)
                await tx.wait(1)
                let details = await onFetchInvDashDetails(data.invDashAddress, data.tokenAddress, Number(data.decimals), data.totalSupply)
                setNetworkData(details)
                setClaimRequested(false)
            } else {
                setClaimRequested(false)
            }
        } catch (e) {
            setClaimRequested(false)
            console.error(e)
        }
    }, [onClaimInvDash, data, claimRequested])

    const handleTransferOwnership = useCallback(async () => {
        try {
            if (transferOwnershipRequested) return
            setTransferOwnershipRequested(true)
            if (!isAddress(newBeneficiary)) {
                setTransferOwnershipRequested(false)
                toast.error("Enter correct address")
                return
            }
            let tx = await onTransferOwnershipInvDash(data.invDashAddress, newBeneficiary)
            if (tx) {
                setTransferOwnershipTxHash(tx.hash)
                await tx.wait(1)
                let details = await onFetchInvDashDetails(data.invDashAddress, data.tokenAddress, Number(data.decimals), data.totalSupply)
                setNetworkData(details)
                setTransferOwnershipRequested(false)
            } else {
                setTransferOwnershipRequested(false)
            }
        } catch (e) {
            setTransferOwnershipRequested(false)
            console.log(e)
        }
    }, [data, newBeneficiary, transferOwnershipRequested])

    return (
        <>
            <Container fluid className='vesting-vault-explorer-container brand-bgcolor-shadow-black pl-0 pr-0 pb-5'>
                <PageHeader title={`$${data?.symbol} Claim Dashboard`} />
                <div className='vault-content brand-color-white'>
                    <div className='project-details-container d-flex flex-lg-row mb-4'>
                        <div className='d-flex project-details col'>
                            <div className='d-flex align-items-center'>
                                {data?.imgUrl && (
                                    <img src={data?.imgUrl} className='mr-4 project-icon' alt='logo' />
                                )}
                                <div>
                                    <h2 className='font-bold mb-0'>{data?.name}</h2>
                                    <h6 className='brand-color mb-0'>{getChainDataById(Number(data?.chainId))?.chainName}</h6>
                                    <p className='text-break'>{data?.tokenAddress}</p>
                                </div>
                            </div>
                        </div>
                        <div className='lock-overview col'>
                            <div className='progress-bar'>
                                <RangeSlider
                                    className='range-slider'
                                    custom={true}
                                    value={(data?.totalTokenLocked / data?.totalSupply) * 100}
                                    type='range'
                                    readOnly={true}
                                />
                            </div>
                            <div className='details-view '>
                                <div className='detail-item d-flex justify-content-between'>
                                    <p className='mb-0'>Total Supply</p>
                                    <p className='mb-0'>{Number(data?.totalSupply).toLocaleString()} {data?.symbol}</p>
                                </div>
                                <div className='detail-item d-flex justify-content-between'>
                                    <p className='mb-0'>Total {data?.symbol} Locked</p>
                                    <p className='mb-0'>
                                        {Number(data?.totalTokenLocked).toLocaleString()} {data?.symbol}
                                    </p>
                                </div>
                                <div className='detail-item d-flex justify-content-between'>
                                    <p className='mb-0'>Total Value Locked</p>
                                    <p className='mb-0'>{data?.totalValueLocked}</p>
                                </div>
                                <div className='detail-item d-flex justify-content-between'>
                                    <p className='mb-0'>Current {data?.symbol} Locked</p>
                                    <p className='mb-0'>
                                        {Number(networkData?.currentGlobalTotalLocked).toLocaleString() ?? 'NA'} {data?.symbol}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {message && (<div className="error-message-container text-center">
                        <h5>{message}</h5>
                    </div>)}
                    {!message && isLoading && (
                        <DotLoader color={"#C2EAFF"} loading={isLoading} css={override} />
                    )}
                    {!message && networkData && (
                        <>
                            <div className='claim-dashboard d-flex flex-column mb-4'>
                                {/* <div className='d-flex justify-content-between w-100 align-items-center mb-3'>
                                    <h4></h4>
                                    <div>
                                        <Button className='btn btn-brand transfer-ownership-button' onClick={() => setTranferModal(true)}>Transfer Wallet</Button>
                                    </div>
                                </div> */}
                                <div className='claim-dashboard-content d-flex flex-lg-row'>
                                    <div className="claim-form-container col">
                                        <div className='claim-dashboard-withdraw d-flex align-items-center w-100'>
                                            <div className="form-container w-100">
                                                <FormLabel>Available For Claim</FormLabel>
                                                <InputGroup className='mb-1'>
                                                    <FormControl className='input-field' disabled={true} value={`${networkData?.releasableAmount} ${data?.symbol}`} />
                                                    <InputGroup.Append>
                                                        <Button
                                                            className='btn btn-brand claim-button'
                                                            onClick={handleClaim}
                                                            disabled={networkData?.releasableAmount == 0}
                                                        >CLAIM</Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                                <div className='next-release-info d-flex justify-content-between'>
                                                    <span>Next Release:</span>
                                                    <span>{networkData?.nextRelease
                                                        ? new Date(networkData?.nextRelease * 1000).toLocaleString().replace(',', ' |')
                                                        : lockList?.length > 0
                                                            ? "ALL CLAIMED"
                                                            : "NOT AVAILABLE"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="account-details-container col">
                                        <div className='claim-dashboard-item d-flex flex-lg-row'>
                                            <div className={`first-container flip-container ${isWithdrawOpen ? "flipped" : ""}`}>
                                                <div className="flipcard-body">
                                                    <div className="front-container">
                                                        <div className="total-withdrawn d-flex flex-column align-items-center">
                                                            <img className="flip-icon" onClick={() => setIsWithdrawOpen(!isWithdrawOpen)} src="../images/icons/right.svg" />
                                                            <span className='withdraw-icon mb-2'>
                                                                <img className="icon-details" src="../images/icons/unlocked.png" />
                                                            </span>
                                                            <span className='mb-2'>Total Claimed</span>
                                                            <span className='withdrawn-amount'>{networkData?.totalReleased.toFixed(2)} {data?.symbol}</span>
                                                        </div>
                                                    </div>
                                                    <div className="back-container">
                                                        <div className="total-withdrawn d-flex flex-column align-items-center">
                                                            <img className="flip-icon" onClick={() => setIsWithdrawOpen(!isWithdrawOpen)} src="../images/icons/right.svg" />
                                                            <span className='withdraw-icon mb-2'>
                                                                <img className="icon-details" src="../images/icons/hourglass.png" />
                                                            </span>
                                                            <span className='mb-2'>Remaining For Claim</span>
                                                            <span className='withdrawn-amount'>{networkData?.totalRemaining.toFixed(2)} {data?.symbol}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`second-container flip-container ${isTotalVestedOpen ? "flipped" : ""}`}>
                                                <div className="flipcard-body">
                                                    <div className="front-container">
                                                        <div className="total-withdrawn d-flex flex-column align-items-center">
                                                            <img className="flip-icon" onClick={() => setIsTotalVestedOpen(!isTotalVestedOpen)} src="../images/icons/right.svg" />
                                                            <span className='withdraw-icon mb-2'>
                                                                <img className="icon-details" src="../images/icons/padlock.png" />
                                                            </span>
                                                            <span className='mb-2'>Total Locked</span>
                                                            <span className='withdrawn-amount'>{networkData?.totalLocked.toFixed(2)} {data?.symbol}</span>
                                                        </div>
                                                    </div>
                                                    <div className="back-container">
                                                        <div className="total-withdrawn d-flex flex-column align-items-center">
                                                            <img className="flip-icon" onClick={() => setIsTotalVestedOpen(!isTotalVestedOpen)} src="../images/icons/right.svg" />
                                                            <span className='withdraw-icon mb-2'>
                                                                <img className="icon-details" src="../images/icons/percentage.png" />
                                                            </span>
                                                            <span className='mb-2'>(%) of Total Supply</span>
                                                            <span className='withdrawn-amount'>{networkData?.totalSupplyPercent.toFixed(2)}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {lockList && lockList.length > 0 && (
                                // <div></div>
                                <IndividualInvDashTable data={lockList} lockTimestamp={data.createdAt}></IndividualInvDashTable>
                            )}
                            <Modal
                                show={tranferModal}
                                onHide={() => setTranferModal(false)}
                                className="brand-color-white"
                                size="lg"
                                centered
                            >
                                <Modal.Header closeButton className="brand-bgcolor-shadow-black">
                                    <Modal.Title className="ml-auto" >
                                        Transfer Wallet
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="brand-bgcolor-shadow-black p-4">
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, vived not only five centuries, but also the leap into</p> */}
                                    <FormLabel className='mb-0'>Current Beneficiary</FormLabel>
                                    <InputGroup className='mb-4'>
                                        <FormControl
                                            className='input-field'
                                            disabled={true}
                                            value={account!} />
                                    </InputGroup>
                                    <FormLabel className='mb-0'>Transfer To Beneficiary</FormLabel>
                                    <InputGroup className='mb-4'>
                                        <FormControl
                                            className='input-field'
                                            disabled={transferOwnershipRequested}
                                            value={newBeneficiary}
                                            onChange={({ target: { value } }) => {
                                                setNewBeneficiary(value)
                                            }} />
                                    </InputGroup>
                                    {/* <Form.Check type="checkbox" id={`check-api-checkbox`} className="mb-4">
                                        <Form.Check.Input type="checkbox" />
                                        <Form.Check.Label>{`You know what you are doing? if not read this again`}</Form.Check.Label>
                                    </Form.Check> */}
                                    <div className="d-flex justify-content-center">
                                        <Button className='btn btn-brand confirm-button' onClick={handleTransferOwnership}>Confirm Transfer Ownership</Button>
                                    </div>

                                    {/* {poolDetails && (
                                        <Form className="d-flex flex-column">
                                            <Form.Group className="mb-2 d-flex flex-column" controlId="formBasicEmail">
                                                <Form.Label>{"Enter Amount you want to Invest (USDT)"}</Form.Label>
                                                <Form.Control
                                                    className="form-input"
                                                    type="number"
                                                    placeholder="Enter USDT"
                                                    value={Number(investAmount).toString()}
                                                    // disabled={poolDetails.invetment > 0}
                                                    onChange={({ target: { value } }) => {
                                                        let val = Number(value)
                                                        if (val) setInvestAmount(val)
                                                        else setInvestAmount(0)
                                                    }} />
                                            </Form.Group>
                                            {(<Button
                                                className="button button-primary button-max"
                                                variant="primary"
                                                onClick={() => setInvestAmount(poolDetails.maxInvest - poolDetails.invetment)}>MAX</Button>)}
                                            <Form.Group className="mb-3 d-flex flex-column" controlId="formBasicPassword">
                                                <Form.Label>You will get ({poolDetails.tokenSymbol})</Form.Label>
                                                <Form.Control
                                                    className="form-input"
                                                    type="text"
                                                    placeholder={poolDetails.tokenSymbol}
                                                    value={Number(reciveAmount) + ' ' + poolDetails.tokenSymbol}
                                                    disabled={true} />
                                            </Form.Group>
                                            {allowance && isApproveDone() && !requestedInvest && <Button
                                                className="button button-primary "
                                                variant="primary"
                                                onClick={() => handleInvest()}
                                                disabled={false}>
                                                Buy
                                            </Button>}
                                            {(!allowance || isApproveOrInvestOrClaimInProgress()) && <Button
                                                className="button button-primary "
                                                variant="primary"
                                                // onClick={() => handleApprove()}
                                                disabled={true}>
                                                <PropagateLoader color={"#C2EAFF"} loading={true} />
                                            </Button>}
                                        </Form>
                                    )} */}
                                </Modal.Body>
                            </Modal>
                        </>
                    )}
                </div>
            </Container>
        </>
    );
};

export default InvestorVestingLockDetails;
