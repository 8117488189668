
export const NetworkContextName = 'NETWORK';

export const CHAINID_URL_MAPPING = [{
    chainId: 1,
    chainName: 'ETH',
    name: 'Etherscan',
    currency: 'ETH',
    url: 'https://etherscan.com/'
}, {
    chainId: 3,
    chainName: 'ETH Testnet',
    name: 'Etherscan',
    currency: 'ETH',
    url: 'https://ropsten.etherscan.io/'
}, {
    chainId: 97,
    chainName: 'BSC Testnet',
    name: 'Bscscan',
    currency: 'BNB',
    url: 'https://testnet.bscscan.com/'
}, {
    chainId: 56,
    chainName: 'BSC',
    name: 'Bscscan',
    currency: 'BNB',
    url: 'https://bscscan.com/'
}, {
    chainId: 321,
    chainName: 'KCC',
    name: 'Scan',
    currency: 'KCC',
    url: 'https://scan.kcc.network'
}, {
    chainId: 322,
    chainName: 'KCC Testnet',
    name: 'Scan',
    currency: 'KCC',
    url: 'https://scan-testnet.kcc.network'
}, {
    chainId: 137,
    chainName: 'Polygon',
    name: 'Polygonscan',
    currency: 'MATIC',
    url: 'https://polygonscan.com/'
}, {
    chainId: 80001,
    chainName: 'MATIC Testnet',
    name: 'Polygonscan',
    currency: 'MATIC',
    url: 'https://mumbai.polygonscan.com'
}, {
    chainId: 43114,
    chainName: 'Avalanche',
    name: 'Snowtrace',
    currency: 'AVAX',
    url: 'https://snowtrace.io/'
},{
    chainId: 43113,
    chainName: 'Avalanche FUJI',
    name: 'Snowtrace',
    currency: 'AVAX',
    url: 'https://testnet.snowtrace.io/'
}]


