import { Contract } from '@ethersproject/contracts'
import INVDASH_ABI from '../constants/abis/invDash.json'
import ERC20_ABI from '../constants/abis/erc20.json'
import { getContract } from './index'

export function getTokenContract(tokenAddress?: string, library?: any, account?: string | null, withSignerIfPossible = true): Contract | null {
    // const { library, account } = useActiveWeb3React()
    if (!tokenAddress || !library || !account) return null
    return getContract(tokenAddress, ERC20_ABI, library ,withSignerIfPossible && account ? account : undefined)
}

export function getInvDashContract(invDashAddress?: string, library?: any, account?: string | null, withSignerIfPossible = true ): Contract | null {
    if (!invDashAddress || !library || !account) return null
    return getContract(invDashAddress, INVDASH_ABI, library, withSignerIfPossible && account ? account : undefined)
}