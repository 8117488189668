import React, { Suspense, useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import BigNumber from 'bignumber.js'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../components/Header'
import Footer from '../components/Footer'
import Web3ReactManager from '../components/Web3ReactManager';


import InvestorLockDetails from './lock-details-investor-vesting';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Collapse } from 'react-bootstrap';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

// import 'react- react-toastify/dist/ReactToastify.css';

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

toast.configure(
  {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  }
)

function App() {
  const [notiHeader, setNotiHeader] = useState(sessionStorage.getItem("notiHeader") === 'true' ? false : true)
  const particlesInit = async (main: any) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };
  const particlesLoaded = (container: any) => {
    console.log(container);
  };
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Web3ReactManager>
          <>
            {/* <Collapse mountOnEnter={true} in={notiHeader} timeout={1000}>
              <div className="airdrop-header">
                <div className="airdrop-header-content">
                  <a href="https://gleam.io/xJCqA/blockpad-early-supporter-airdrop-event">BlockPad early supporter airdrop event is now live.</a></div>
                <div className="airdrop-header-close" onClick={() => {
                  setNotiHeader(false)
                  sessionStorage.setItem("notiHeader", "true")
                }}>X</div>
              </div>
            </Collapse> */}
            <Particles
              id="tsparticles"
              init={particlesInit}
              // loaded={particlesLoaded}
              options={{
                "particles":{
                   "number":{
                      "value":25,
                      "density":{
                         "enable":true,
                         "value_area":800
                      }
                   },
                   "color":{
                      "value":"#ffffff"
                   },
                   "shape":{
                      "type":"circle",
                      "stroke":{
                         "width":0,
                         "color":"#000000"
                      },
                      "polygon":{
                         "nb_sides":5
                      },
                      "image":{
                         "src":"img/github.svg",
                         "width":100,
                         "height":100
                      }
                   },
                   "opacity":{
                      "value":0.3,
                      "random":false,
                      "anim":{
                         "enable":false,
                         "speed":1,
                         "opacity_min":0.1,
                         "sync":false
                      }
                   },
                   "size":{
                      "value":3,
                      "random":true,
                      "anim":{
                         "enable":false,
                         "speed":40,
                         "size_min":0.1,
                         "sync":false
                      }
                   },
                   "line_linked":{
                      "enable":true,
                      "distance":150,
                      "color":"#ffffff",
                      "opacity":0.4,
                      "width":1
                   },
                   "move":{
                      "enable":true,
                      "speed":9,
                      "direction":"none",
                      "random":false,
                      "straight":false,
                      "out_mode":"out",
                      "bounce":false,
                      "attract":{
                         "enable":false,
                         "rotateX":600,
                         "rotateY":1200
                      }
                   }
                },
                "interactivity":{
                   "detect_on":"canvas",
                   "events":{
                      "onhover":{
                         "enable":true,
                         "mode":"repulse"
                      },
                      "onclick":{
                         "enable":false,
                         "mode":"push"
                      },
                      "resize":true
                   },
                   "modes":{
                      "grab":{
                         "distance":400,
                         "line_linked":{
                            "opacity":1
                         }
                      },
                      "bubble":{
                         "distance":400,
                         "size":40,
                         "duration":2,
                         "opacity":8,
                        //  "speed":3
                      },
                      "repulse":{
                         "distance":200,
                         "duration":0.4
                      },
                      "push":{
                         "particles_nb":4
                      },
                      "remove":{
                         "particles_nb":2
                      }
                   }
                },
                "retina_detect":true
             }}
            />
            <Header></Header>
            <div className="body-content">
              <Routes>
                <Route path="/:tokenAddress" element={<InvestorLockDetails />} />
                <Route path="" element={<InvestorLockDetails />} />
              </Routes>
            </div>
            <Footer></Footer>
          </>
        </Web3ReactManager>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
