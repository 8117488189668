import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { CHAINID_URL_MAPPING } from '../constants'

export function isAddress(value: any): string | false {
    try {
        return getAddress(value)
    } catch {
        return false
    }
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
    return library.getSigner(account).connectUnchecked()
  }

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
    return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`Invalid 'address' parameter '${address}'.`)
    }

    return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function getExplorerUrl(chainId: number | undefined) {
    let obj = CHAINID_URL_MAPPING.find(o => o.chainId === chainId)
    if(!obj) return null
    return obj?.url
}

export function getExplorerUrlFromName(name: string) {
    let obj = CHAINID_URL_MAPPING.find(o => o.chainName === name)
    if(!obj) return null
    return obj?.url
}

export function getExplorerName(chainId: number | undefined) {
    let obj = CHAINID_URL_MAPPING.find(o => o.chainId === chainId)
    if(!obj) return null
    return obj?.name
}

export function getExplorerNameFromName(name: string) {
    let obj = CHAINID_URL_MAPPING.find(o => o.chainName === name)
    if(!obj) return null
    return obj?.name
}

export function getCurrencyName(chainId: number | undefined) {
    let obj = CHAINID_URL_MAPPING.find(o => o.chainId === chainId)
    if(!obj) return null
    return obj?.currency
}

export function getChainDataById(chainId: number | undefined) {
    let obj = CHAINID_URL_MAPPING.find(o => o.chainId === chainId)
    if(!obj) return null
    return obj
}

export function getEllipsisAddress(address: string) {
    return `${address.substring(0, 5)}...${address.substring(address.length - 5)}`
}