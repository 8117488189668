import React, { FunctionComponent } from "react";
import NumberFormat from "react-number-format";
import RangeSlider from "../RangeSlider";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import "./individualInvDashTable.scss";

type dataObj = {
    claimed: boolean;
    amount: number;
    releaseTime: number;
};

type InvDashTableProps = {
    data: Array<dataObj>;
    lockTimestamp: any;
};

const IndividualInvDashTable: FunctionComponent<InvDashTableProps> = ({ data, lockTimestamp }) => {
    dayjs.extend(relativeTime);
    return (
        <>
            <div className='individual-invdash-table-container'>
                <div className="table-container ">
                    <table className='vault-table'>
                        <colgroup>
                            <col span={1} width='7%' />
                            <col span={1} width='25%' />
                            <col span={1} width='10%' />
                            <col span={1} width='23%' />
                            <col span={1} width='35%' />
                            {/* <col span={1} width='auto' /> */}
                            {/* <col span={1} width='20%' /> */}
                        </colgroup>
                        <thead>
                            <tr className="text-center">
                                <th>Sr.</th>
                                <th>Release Time</th>
                                <th>Token</th>
                                <th>Status</th>
                                <th></th>
                                {/* <th>next release</th> */}
                                {/* <th></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((obj, index) => (
                                <tr key={`tr-${index}`} className="text-center">
                                    <td >{index + 1}</td>
                                    <td>{new Date(obj.releaseTime * 1000).toLocaleString().replace(',', ' |')}</td>
                                    <td className="text-center">
                                        <NumberFormat
                                            value={Math.floor(obj.amount * 10000) / 10000}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </td>
                                    <td className="text-center status-container">
                                        {obj.claimed && (
                                            <div className="status-card d-flex align-items-center">
                                                <div className="img-contaniner">
                                                    <img className="img" src="../images/lock-details-withdrawn.svg" />
                                                </div>
                                                <div className="text-container">
                                                    <h6 className="mb-0">WITHDRAWN</h6>
                                                </div>
                                            </div>
                                        )}
                                        {!obj.claimed && Date.now() <= (obj.releaseTime * 1000) && (
                                            <div className="status-card d-flex align-items-center">
                                                <div className="img-contaniner">
                                                    <img className="img" src="../images/lock-details-lock.svg" />
                                                </div>
                                                <div className="text-container">
                                                    <h6 className="mb-0">LOCKED</h6>
                                                </div>
                                            </div>
                                        )}
                                        {!obj.claimed && Date.now() > (obj.releaseTime * 1000) && (
                                            <div className="status-card d-flex align-items-center">
                                                <div className="img-contaniner">
                                                    <img className="img" src="../images/lock-details-unlock.svg" />
                                                </div>
                                                <div className="text-container">
                                                    <h6 className="mb-0">UNLOCKED</h6>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        <p className="mb-0 d-flex justify-content-center align-items-center"> <p className="mr-1 mb-0">Unlock Countdown:</p>
                                            {obj.releaseTime > (Date.now() / 1000) && (<span className="h6 mb-0">{dayjs(dayjs.unix(obj.releaseTime).format("YYYY-MM-DD HH:mm:ss")).toNow(true)}</span>)}
                                            {obj.releaseTime < (Date.now() / 1000) && (<span className="h6 mb-0"> 0 Sec</span>)}
                                        </p>
                                        <div className="progress-bar">
                                            <RangeSlider
                                                className="range-slider"
                                                custom={true}
                                                // value={0}
                                                value={100 - (obj.releaseTime - (Date.now() / 1000)) / (obj.releaseTime - (new Date(lockTimestamp).getTime() / 1000)) * 100}
                                                type='range'
                                                readOnly={true}
                                            />
                                        </div>
                                    </td>


                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default IndividualInvDashTable;
