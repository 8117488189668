import { useCallback, useEffect, useState } from 'react'
import { getTokenContract, getInvDashContract } from '../utils/contractHelpers'
import { useActiveWeb3React } from '.'
import { toast } from 'react-toastify'
import { getBalanceNumber } from '../utils/formatBalance'
import { claimInvDash, transferOwnershipInvDash } from '../utils/callHelpers'

const getReleaseObject = function (amount: number, released: number, cliffTime: number, periodicity: number, duration: number) {
    let totalReleaseIterations = duration / periodicity
    let perInterationReleaseAmount = amount / totalReleaseIterations
    let releasedIterations = released / perInterationReleaseAmount
    let releaseStructure = []
    let releaseTimes = []
    for (let i = 0; i < totalReleaseIterations; i++) {
        let releaseTime = cliffTime + (i * periodicity)
        releaseTimes.push(releaseTime)
        releaseStructure.push({
            releaseTime,
            amount: perInterationReleaseAmount,
            claimed: i < releasedIterations
        })
    }
    return {
        releaseStructure,
        releaseTimes
    }
}

const getNextReleaseTime = function (releaseTimeList: any) {
    let sortedReleaseTimeList = releaseTimeList.sort()
    let nextRelease;
    for (let i = 0; i < sortedReleaseTimeList.length; i++) {
        if (!nextRelease && sortedReleaseTimeList[i] > (Date.now() / 1000)) {
            nextRelease = sortedReleaseTimeList[i]
            break;
        }
    }
    return nextRelease
}

function compareReleaseTime(a: any, b: any) {
    if (a.releaseTime < b.releaseTime) {
        return -1;
    }
    if (a.releaseTime > b.releaseTime) {
        return 1;
    }
    return 0;
}

export const useClaimInvDash = () => {
    const { library, account, chainId } = useActiveWeb3React()

    const handleClaimInvDash = useCallback(async (invDashAddress: string) => {
        try {
            let invDashContract = getInvDashContract(invDashAddress, library, account)
            if (!library || !account || !chainId || !invDashContract) return false
            const tx = await claimInvDash(invDashContract)
            return tx
        } catch (e: any) {
            toast.error(e.data.message || e.message)
            return false
        }
    }, [account, chainId, library])

    return { onClaimInvDash: handleClaimInvDash }
}

export const useTransferOwnershipInvDash = () => {
    const { library, account, chainId } = useActiveWeb3React()

    const handleTransferOwnership = useCallback(async (invDashAddress: string, newBeneficiary: string) => {
        try {
            let invDashContract = getInvDashContract(invDashAddress, library, account)
            if (!library || !account || !chainId || !invDashContract || !newBeneficiary) return false
            const tx = await transferOwnershipInvDash(invDashContract, newBeneficiary)
            return tx
        } catch (e: any) {
            toast.error(e.data.message || e.message)
            return false
        }
    }, [account, chainId, library])

    return { onTransferOwnershipInvDash: handleTransferOwnership }
}

export const useFetchInvDashDetails = () => {
    const { library, account, chainId } = useActiveWeb3React()
    const emergencyWithdraw = ["5", "6", "7", "8", "9", "10"]
    const handleFetchDetails = useCallback(async (invDashAddress: string, tokenAddress: string, tokenDecimals: number, totalSupply: number) => {
        try {
            let invDashContract = getInvDashContract(invDashAddress, library, account)
            let tokenContract = getTokenContract(tokenAddress, library, account)
            if (!invDashContract || !tokenAddress) return false
            let releasableAmount
            let locks: any = []
            let totalReleased = 0
            let totalLocked = 0
            const lockIds = await invDashContract?.getLockIds(account)
            const currentTotalLocked = await tokenContract?.balanceOf(invDashAddress)
            const lockList = []
            const releaseList = []
            if (lockIds.length > 0) {
                releasableAmount = await invDashContract?.getReleasableAmount(account)
                for (let i = 0; i < lockIds.length; i++) {
                    let data = await invDashContract?.lockInfos(lockIds[i])
                    let amount = getBalanceNumber(data.amount, tokenDecimals)
                    if (amount <= 0) continue;
                    let released = getBalanceNumber(data.released, tokenDecimals)
                    let cliffTime = getBalanceNumber(data.cliffTime, 0)
                    let duration = getBalanceNumber(data.duration, 0)
                    let periodicity = getBalanceNumber(data.periodicity, 0)
                    let { releaseStructure, releaseTimes } = getReleaseObject(amount, released, cliffTime, periodicity, duration)
                    if(!emergencyWithdraw.includes(lockIds[i].toString())) {
                        locks.push({
                            amount,
                            released,
                            cliffTime,
                            duration,
                            periodicity,
                            releaseTimes,
                            releaseStructure
                        })
                        totalLocked += amount
                        totalReleased += released
                        lockList.push(...releaseStructure)
                        releaseList.push(...releaseTimes)
                    }
                }
            }
            return {
                currentGlobalTotalLocked: getBalanceNumber(currentTotalLocked, tokenDecimals),
                releasableAmount: releasableAmount ? getBalanceNumber(releasableAmount, tokenDecimals) : 0,
                lockIds,
                locks,
                totalLocked,
                totalReleased,
                totalRemaining: totalLocked - totalReleased,
                totalSupplyPercent: (totalLocked * 100) / totalSupply,
                nextRelease: getNextReleaseTime(releaseList),
                lockList: lockList.sort(compareReleaseTime)
            }
        } catch (e: any) {
            console.log(e)
            toast.error(e.data.message || e.message)
            return false
        }
    }, [account, chainId, library])

    return { onFetchInvDashDetails: handleFetchDetails }
}