import React from 'react'
import './footer.css'
import Logo from "../Header/images/logo.png";

const Footer = () => (
    <footer className="footer mt-auto pt-5 pb-3">
        <div className="container text-center">
            <div className="divider mb-3"></div>
            <div className="copyright-section d-flex justify-content-between align-items-center">
                <small className="brand-color-white mb-0"><a className="footer-link" href="https://avoteo.io">Avoteo</a> © 2022</small>
                <div className='d-flex align-items-center'>
                    <small className="brand-color-white mr-1">Powered By </small> 
                    <a href="https://blockius.io" target="_blank"><img alt="logo" width={100} src={Logo} className='logo-powered-by' ></img></a>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
